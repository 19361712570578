import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="How to sell a car on consignment"
      description="Before selling your car on consignment through a dealer, read our top tips "
      location={location}
    />

    <PageHeader
      title="How to sell a car on consignment"
      text="Before selling your car on consignment through a dealer, read our top tips "
    />

    <SectionPageMargin>

      <Heading2>Consignment vehicle sales - the good, the bad and the ugly</Heading2>

      <Paragraph1>
        <strong>What is selling on consignment?</strong><br />
        If you wish to sell a vehicle, rather than accepting a low dealer trade-in offer (or wholesaler offer), you may wish to have the dealership or broker sell on your behalf - without purchasing it from you.
      </Paragraph1>

      <Paragraph1>
        This is called a vehicle consignment, and is offered by many dealers around Australia, except in Victoria, where consignment is banned for Victorian Licenced Motor Car Traders (LMCT).
      </Paragraph1>

      <Paragraph1>
        <strong>Why sell on consignment?</strong><br />
        There are many benefits of selling in this way, the biggest being not having to deal with tyre-kickers and low-ball offers, and saving you the time of doing inspections and test-drives.
      </Paragraph1>


      <Paragraph1>
        However, selling on consignment is not without its risks, and it has been speculated that the reason for the ban in Victoria is due to some negligent or fraudulent behaviour of dealerships in the past. This bad behaviour is not confined to Victoria of course, but it’s only the Victorian State Government who shut down consigned vehicle sales to protect the public.
      </Paragraph1>

      <Paragraph1>
        Whether this reaction and stance is justified or not (the Victorian Automobile Chamber of Commerce is trying to have the ban lifted) is not for us to comment, but it seems like consumers (both buyers and sellers) could be protected in better ways, as they are in other states.
      </Paragraph1>

      <Paragraph1>
        <strong>Our tips for consignment vehicle sales</strong><br />
        If you choose to take advantage of having your car sold on your behalf, here are our tips;
      </Paragraph1>

      <ol style={{fontWeight: 'bold'}}>
        <li>
          <strong>Get it in writing</strong><br />
          <Paragraph>Ensure the dealership provides you with a written and signed agreement which outlines their responsibilities and yours, and document that the vehicle is owned by you - in particular if they are storing the car for you (at a dealership premises or otherwise). You should also check what the insurance cover for your vehicle is during the care of the consignor.</Paragraph>
        </li>
        <li>
          <strong>Show me the money!</strong><br />
          <Paragraph>Once your car is sold (or deposit is taken), be clear with the dealership on where the funds will be held (e.g. a Trust account) and how quickly funds will be released to you. Avoid the situation where you are without your car and also the money!</Paragraph>
        </li>
        <li>
          <strong>Only consign with a reputable company</strong><br />
          <Paragraph>Do your homework, does the dealership or broker have a reputable track record, do they have good customer reviews, can you speak to a customer referee? Of course most dealerships and brokers are great people, and very trustworthy. Don’t let a bad apple spoil your consignment sale!</Paragraph>
        </li>
        <li>
          <strong>Selling on consignment in Victoria</strong><br />
          <Paragraph>As noted above, a Licenced Motor Car Trader (LMCT) registered in Victoria is not allowed to sell a vehicle on consignment, or sell on behalf of a customer.</Paragraph>
          <Paragraph>
            Summon offer a marketing service, and are not a LMCT, meaning our company doesn’t have this same restriction and we are able to legally sell cars on behalf of the owners (we operate in VIC, QLD and NSW)
          </Paragraph>
        </li>
        <li>
          <strong>Can you do without your car for 30 - 60 days?</strong><br />
          <Paragraph>Most dealerships will take a consigned vehicle and place it in their dealerships. This has the benefit of potential foot-traffic seeing your car, but it also means you are without your car for the sale period (typically 30 - 60 days). This is probably fine for your classic Porsche (which may be garaged for long periods anyway), but not for your daily driver BMW, Audi or Mercedes.</Paragraph>
        </li>
        <li>
          <strong>Car valuations when selling on consignment</strong><br />
          <Paragraph>If you are speaking to a dealership or broker about a consignment sale, they will do a valuation and provide you with an expected sale price.</Paragraph>
          <Paragraph>
            Be wary if the valuation is too good to be true. Because the consignor is not taking a risk on the purchase of the vehicle, it’s possible for someone to exaggerate the potential sale price in order to get the seller to sign-up for consigning their car. Do your own research, or get a valuation from another source to validate it.
          </Paragraph>
          <Paragraph>
            For prestige cars over $50,000 we offer a <Link to="https://value.summon.com.au//">free independent valuation service</Link>.
          </Paragraph>
        </li>
        <li>
          <strong>How much does selling a car on consignment cost?</strong><br />
          <Paragraph>Fees will vary, but from our research into prestige (Audi, BMW, Mercedes-Benz, Range Rover, Porsche etc) car consignment fees tend to be between 6% and 12% commission on the final agreed sale price.</Paragraph>
          <Paragraph>
            A key factor in the price is whether the car stays with you the customer (as with Summon’s service), or if a dealership will be displaying your shiny used BMW in their showroom (which means they have cost overheads for rent, insurance etc).
          </Paragraph>
          <Paragraph>
            There will also usually be an upfront fee to cover the costs of advertising, photography etc.
          </Paragraph>
          <Paragraph>
            Be wary of any surprise costs, such as a dealership requiring you to pay for reconditioning, or being locked into the consigning dealership for a service.
          </Paragraph>
        </li>
      </ol>

      <Paragraph1>
        If you are interested to sell on consignment, our service provides the same benefits; we save you all the hassle, while we professionally manage the sale to get you the best possible price.
      </Paragraph1>


      <Paragraph1>
        The car stays with you, the owner, throughout the sale process. We do not have any dealership premises, and so can pass this cost saving back to you with a low commission fee.
      </Paragraph1>

      <Paragraph1>
      Please get in touch for a <Link to="https://value.summon.com.au//">free valuation</Link> or to <Link to="/contact/">ask any questions</Link>.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage

const Paragraph = styled.p`
    color: ${props => props.color};
    font-size: 1.1rem;
    line-height: 1.7rem;
    letter-spacing: -0.05rem;
    margin-top: 0px;
    font-weight: 400;
`

